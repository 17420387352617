(function($){
	const siteName = 'cellseed';
	const a = 'is-active';
	const v = 'is-visible';
	const slideSpeed = '400';

	SLSD = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision(){
			var regExp = new RegExp(`(test\\.${siteName}\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading() { // ローカル環境ならincludeをAjaxで読み込む
			const _this = this;
			const key = 'inc_';
			let ajaxArray = [];
			let ajaxArrayList = [];
			let includeClass = document.querySelectorAll(`[class*="${key}"]`);

			includeClass.forEach(ele => {
				let path = ele.innerHTML.split(' ')[1];
				ajaxArray.push(path);
			});

			if (ajaxArray.length) {
				ajaxArray.forEach(ele => {
					let xhr = new XMLHttpRequest();
					let promise = new Promise((resolve, reject) => {
						xhr.onreadystatechange = function () {
							if (xhr.readyState === 4) {
								if (xhr.status === 200) {
									resolve(xhr.responseText);
								} else {
									reject(xhr.statusText);
								}
							}
						};
					});
					xhr.open('GET', ele + '.html', true);
					xhr.send();
					ajaxArrayList.push(promise);
				});

				Promise.all(ajaxArrayList).then(results => {
					let regExp = new RegExp(key);

					results.forEach(ele => {
						let classList = ele.split(/ |\"/g);
						let position;

						classList.forEach(ele2 => {
							if (ele2.match(regExp)) { position = ele2; }
						});
						document.querySelectorAll('.' + position).forEach(ele2 => {
							ele2.outerHTML = ele;
						});
						console.log('Succeeded to read the include file!:', position);
					});

					_this.loadDelayScript();
				}).catch((error) => {
					console.error('Failed to read the include file:', error);
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		getQuery(){
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for(var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle(){
			var $switch = $('.js-toggle');

			$switch.on('click', function(){
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll(){
			$('a[rel="scroll"]').on('click', (event) => {
				var speed = 1200;
				if(/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed){
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if(hash !== '#pagetop') {
					if (SLSD.va.window.width <= SLSD.va.device.desktop) {
						pos -= 60;
					} else {
						pos -= 100;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		headerMenu(){
			const $header = $('.header');
			const $menuBtn = $('.header-menuBtn');
			const $headerMenu = $('.header-menuBlock');
			const $headerLang = $('select[name="header-lang"]');
			// const s = 'is-small';

			// スクロールしたらヘッダー縮小
			// $(window).scroll((e) => {
			// 	let $this = $(e.currentTarget);
			// 	let thisScroll = $this.scrollTop();
			// 	if(thisScroll > 120) {
			// 		$header.addClass(s);
			// 	} else {
			// 		$header.removeClass(s);
			// 	}
			// });
			// if($(window).scrollTop() > 120) {
			// 	$header.addClass(s);
			// } else {
			// 	$header.removeClass(s);
			// }

			// menu開閉
			$menuBtn.on('click', (e) => {
				let $this = $(e.currentTarget);
				$this.toggleClass(a);
				$headerMenu.toggleClass(a);
			});

			$headerLang.on('change', (e) => {
				$(e.currentTarget).children('option').each((idx, ele) => {
					if($(ele).prop('selected') === true) {
						if($(ele).val() === 'ENGLISH') {
							location.href = '/en/';
						} else {
							location.href = '/';
						}
					}
				});
			});
		},
		pageTop(){
			var $pagetop = $('.footer-pageTop');

			$(window).scroll((e) => {
				let $this = $(e.currentTarget);
				let $footer = $('.footer');
				let footerPos = $footer.offset().top + $footer.height();
				let thisScroll = $this.scrollTop();
				let pagetopPos = $this.height() + $footer.height() + thisScroll;
				if (SLSD.va.window.width <= 1024) {
					pagetopPos += 70;
				}

				if (thisScroll > '100') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}

				if (footerPos <= pagetopPos) {
					$pagetop.addClass('is-bottom');
				} else {
					$pagetop.removeClass('is-bottom');
				}
			});
		},
		navCurrent(){
			const $navItem = $('.js-nav-current');
			const $map = SLSD.va.pathname;
			if($navItem.length) {
				$navItem.each((idx, ele) => {
					if($map === $(ele).attr('href')) {
						$(ele).addClass(a);
					}
				});
			}
		},
		loadDelayScript(){
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.headerMenu();
			_this.pageTop();
			_this.navCurrent();
		}
	};

	$(() => SLSD.localDecision() ? SLSD.localLoading() : SLSD.loadDelayScript());
})(window.jQuery);
