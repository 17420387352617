(function($, SLSD){
	const a = 'is-active';
	const v = 'is-visible';
	const slideSpeed = '400';

	const tabChange = () => {
		if($('.mod-tabChange').length) {
			const $hash = location.hash;
			if($hash.length) {
				$('.mod-tabChange-btn').each((idx, ele) => {
					if($(ele).attr('data-name') === $hash.replace('#', '')) {
						$(ele).addClass(a);
						$('.mod-tabChange-content').eq(idx).addClass(a);
					}
				});
			} else {
				$('.mod-tabChange-content').eq(0).addClass(a);
				$('.mod-tabChange-btn').eq(0).addClass(a);
			}

			$('.mod-tabChange-btn').each(function(){
				$(this).on('click', function(){
					var index = $('.mod-tabChange-btn').index(this);
					$('.mod-tabChange-btn').removeClass(a);
					$(this).addClass(a);
					$('.mod-tabChange-content').removeClass(a);
					$('.mod-tabChange-content').eq(index).addClass(a);
				});
			});
		}
	};
	// ＜mod-buttonホバー＞TABLET・SP用
	const spButtonHover = () => {
		$('.mod-button-a')
		.on('touchstart', function(){
			$(this).addClass('js-sp-hover');
		}).on('touchend', function(){
			$(this).removeClass('js-sp-hover');
		});

	};
	// ＜ルート設定対応＞要素が空だったら削除
	const removeElmIfEmpty = () => {
		$('.js-checkEmptyElm').each((idx, val) => {
			if (!$(val).text().length) {
				$(val).remove();
			}
		});
	}
	// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
	const removeBlockIfListEmpty = () => {
		if ($('.js-checkEmptyList').length) {
			$('.js-checkEmptyList').each((idx, val) => {
				if (!$(val).find('li').length) {
					$(val).parents('.js-checkEmptyList-wrap').remove();
				}
			});
		}
	};
	// ファイルサイズ変換
	const fileSize = () => {
		$('.js-fileSize').each((idx, ele) => {
			$(ele).text(bytesToSize($(ele).text()));
		});

		function bytesToSize(bytes){
			const sizes = ['Bytes', 'KB', 'MB', 'GB'];
			if(bytes === 0) return '0 Byte';
			let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
			let convSize = Math.floor(bytes / Math.pow(1024, i)* Math.pow(10, 1)) / Math.pow(10, 1);
			// KBのとき、小数点切り捨て
			if(i === 1) convSize = Math.floor(convSize);
			return convSize + sizes[i];
		}
	};

	let winW = $(window).width();
	if(winW <= 1024) {
		spButtonHover();
	}

	tabChange();
	removeElmIfEmpty();
	removeBlockIfListEmpty();
	fileSize();

})(window.jQuery, window.SLSD);
